export {default as headerDecoration} from './header-decoration.png';
export {default as fantasyImage} from './fantasy-image.webp';
export {default as fantasyIcon} from './fantasy-icon.png';
export {default as tacticImage} from './tactic-image.webp';
export {default as tacticIcon} from './tactic-icon.png';
export {default as triviaSportsImage} from './trivia-sports-image.png';
export {default as triviaSportsIcon} from './trivia-sports-icon.png';
export {default as triviaArtImage} from './trivia-art-image.webp';
export {default as triviaArtIcon} from './trivia-art-icon.png';
export {default as triviaDerbyImage} from './derby.png';
export {default as triviaKataflamImage} from './kataflam.webp';
export {default as fantasySabahoKoraImage} from './fantasy-sabaho-kora-image.png';
export {default as triviaSportsSabahoKoraImage} from './trivia-sabaho-kora-image.png';
export {default as tacticErza3Image} from './tactic-erza3-image.jpeg';
export {default as triviaSportsJosFootyImage} from './trivia-sports-jos-footy-image.jpeg';
export {default as triviaArtBeta3AflamImage} from './trivia-art-beta3-aflam-image.jpeg';
export {default as triviaSportsAhly} from './trivia-sports-ahly-logo.png';
export {default as splLogo} from './spl-logo.png';
export {default as rsffLogo} from './rsff-logo.png';
